import React, {useEffect, useState} from "react";
import { Check, ChevronsUpDown } from "lucide-react"

import { cn } from "@/lib/utils"
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/components/ui/command"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"
import {CourseChapterDTO, CourseLevelDTO} from "@/generated";
import {Button} from "@/components/ui/button";


export const CourseChapterFilter = (props: any) => {
    const [open, setOpen] = React.useState(false)
    const { onSelectCourseChapter, availableCourseChapters } = props;
    const [selectedCourseChapter, setSelectedCourseChapter] = useState<CourseChapterDTO>()
    const findCourseChapterByName = (name: string) : CourseChapterDTO => {
        return availableCourseChapters.find((chapter : CourseChapterDTO) => chapter.name === name);
    }
    useEffect(() => {
        onSelectCourseChapter(selectedCourseChapter);
    }, [selectedCourseChapter]);
    return (
        <>
            <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                    <Button
                        variant="outline"
                        color="primary"
                        role="combobox"
                        aria-expanded={open}
                        className="min-w-[280px] justify-between"
                    >
                        {selectedCourseChapter
                            ? selectedCourseChapter.name
                            : "Tous les chapitres"}
                        <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="min-w-[280px] p-0">
                    <Command>
                        <CommandInput placeholder="Rechercher..." />
                        <CommandList>
                            <CommandEmpty>Aucune donnée</CommandEmpty>
                            <CommandGroup>
                                <CommandItem key="-1" value="Tous les chapitres" onSelect={() => {
                                    setSelectedCourseChapter(undefined);
                                    setOpen(false)
                                }}>
                                    <Check
                                        className={cn(
                                            "mr-2 h-4 w-4",
                                            selectedCourseChapter ? "opacity-0" : "opacity-100"
                                        )}
                                    />
                                    Tous les chapitres
                                </CommandItem>
                                {availableCourseChapters?.map((chapter : CourseLevelDTO) => (
                                    <CommandItem
                                        key={chapter.id}
                                        value={chapter.name}
                                        onSelect={(currentValue) => {
                                            setSelectedCourseChapter(findCourseChapterByName(currentValue))
                                            setOpen(false)
                                        }}
                                    >
                                        <Check
                                            className={cn(
                                                "mr-2 h-4 w-4",
                                                selectedCourseChapter === chapter ? "opacity-100" : "opacity-0"
                                            )}
                                        />
                                        {chapter.name}
                                    </CommandItem>
                                ))}
                            </CommandGroup>
                        </CommandList>
                    </Command>
                </PopoverContent>
            </Popover>

        </>
    );
};
