import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import ErrorComponent from "./shared/ErrorComponent";
import VideoDetails from "./pages/VideoDetails";
import SearchResult from "./pages/SearchResult";
import MainLayout from "./layouts/MainLayout";

import Home from "./pages/Home";
import Favorite from "./pages/Favorite";
import History from "./pages/History";
import Category from "./pages/Category";
import ProtectedLayout from "./layouts/ProtectedLayout";
import PublicLayout from "./layouts/PublicLayout";
import { OAuthContext } from "./context/AuthContext.tsx";
import { useContext } from "react";
import { Register } from "./pages/public/Register";
import { PaymentWizard } from "./pages/PaymentWizard";
import { ActivateMembership } from "./pages/ActivateMembership";
import MainLayout1 from "./layouts/MainLayout1";
import { Profile } from "./pages/settings/Profile";
import { SettingsLayout } from "./layouts/SettingsLayout";
import { Membership } from "./pages/settings/Membership";
import { Security } from "./pages/settings/Security";
import { Devices } from "./pages/settings/Devices";
import ViewCourse from "./pages/ViewCourse";
import React from "react";

export let appRouter: any;

export const App = () => {
  //check if sso config has been initialized prior to any navigation
  const { ssoInitialized } = useContext(OAuthContext);

  appRouter = createBrowserRouter([
    {
      path: "/",
      element: <ProtectedLayout />,
      errorElement: <ErrorComponent />,
      children: [
        //all protected routes should go here
        {
          path: "/",
          element: <MainLayout />,
          children: [
            { path: "/", element: <Home /> },
            { path: "/cours", element: <Home /> },
            { path: "/cours/:courseUUID", element: <ViewCourse /> },
            { path: "/favoris", element: <Favorite /> },
            { path: "/historique", element: <History /> },
            { path: "/categorie/:categoryUUID", element: <Category /> },
            { path: "/recherche/:searchQuery", element: <SearchResult /> },
            { path: "/searchResult/:searchQuery", element: <SearchResult /> },
            { path: "/abonnement", element: <ActivateMembership /> },
            {
              path: "/parametres",
              element: <SettingsLayout />,
              children: [
                { path: "/parametres", element: <Membership /> },
                { path: "/parametres/abonnement", element: <Membership /> }
              ],
            }
          ],
        },
        { path: "/video/:id", element: <VideoDetails /> },
        { path: "/paiement", element: <PaymentWizard /> },
      ],
    },
    {
      path: "/",
      element: <PublicLayout />,
      errorElement: <ErrorComponent />,
      children: [
        // all public routes should go here
        { path: "/register", element: <Register /> },
      ],
    },
  ]);

  return <>{ssoInitialized && <RouterProvider router={appRouter} />}</>;
};
