import React from "react";
import {useState} from "react";


export const PaymentCategory = ({step, setStep, paymentData, setPaymentData}) => {

    const [isCategoryVoucher, setIsCategoryVoucher] = useState<boolean>(paymentData?.paymentMethod==='VOUCHER');
    const applyPaymentCategory = () => {
        setPaymentData({...paymentData, paymentMethod: isCategoryVoucher ? 'VOUCHER':''})
        setStep(step+1)
    }

    return (
        <div className="max-w-md mx-auto px-4">

            <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">Je souhaite m'abonner</h3>
            <ul className="mt-6 space-y-3">
                <li>
                    <label htmlFor='web' className="block relative">
                        <input id='web' type="radio" checked={!isCategoryVoucher}
                               onChange={(e)=>setIsCategoryVoucher(!e.target.checked)}
                               name="payment" className="sr-only peer"/>
                        <div
                            className="w-full p-5 cursor-pointer rounded-lg border bg-white shadow-sm ring-orange-white peer-checked:ring-2 duration-200">
                            <div className="pl-7">
                                <h3 className="leading-none text-gray-800 font-medium">
                                    Par Mobile Money
                                </h3>
                                <p className="mt-1 text-sm text-gray-600">
                                    Airtel Money, MPESA, Orange Money
                                </p>
                            </div>
                        </div>
                        <span
                            className="block absolute top-5 left-5 border peer-checked:border-[5px] peer-checked:border-orange-white w-4 h-4 rounded-full">
                                </span>
                    </label>
                </li>

                <li>
                    <label htmlFor='voucher' className="block relative">
                        <input id='voucher' type="radio" checked={isCategoryVoucher}
                               onChange={(e)=>setIsCategoryVoucher(e.target.checked)}
                               name="payment"
                               className="sr-only peer"/>
                        <div
                            className="w-full p-5 cursor-pointer rounded-lg border bg-white shadow-sm ring-orange-white peer-checked:ring-2 duration-200">
                            <div className="pl-7">
                                <h3 className="leading-none text-gray-800 font-medium">
                                    Par Carte de recharge
                                </h3>
                                <p className="mt-1 text-sm text-gray-600">
                                    Si vous détenez une carte de recharge
                                </p>
                            </div>
                        </div>
                        <span
                            className="block absolute top-5 left-5 border peer-checked:border-[5px] peer-checked:border-orange-white w-4 h-4 rounded-full">
                                </span>
                    </label>
                </li>

            </ul>
            <a onClick={() => applyPaymentCategory()} href="#"
               className="block mt-8 py-2 px-4 text-center text-white font-medium bg-orange-white duration-150 hover:bg-orange active:bg-orange rounded-lg shadow-lg hover:shadow-none">
                Suivant
            </a>
        </div>
    )
}
