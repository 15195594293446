import { useContext, useEffect, useState } from "react";

import UseOnline from "../utils/UseOnline";
import OfflineComponent from "../shared/OfflineComponent";
import ShimmerVideoCard from "../shared/ShimmerVideoCard";
import { axiosClient } from "../utils/HttpClient";
import CourseCard from "../components/CourseCard";
import { CourseLevelFilter } from "@/components/CourseLevelFilter";
import { CourseDTO } from "@/generated";
import { useSearchParams } from "react-router-dom";
import { Pagination } from "@/components/Pagination";
import { DataContext } from "../context/AppContext";

const Home = () => {
  //const { loading, recentCourses } = useContext(DataContext);
  const isOnline = UseOnline();
  const [courses, setCourses] = useState<Array<CourseDTO>>([]);
  const [loading, setLoading] = useState(true);
  let [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams?.get("query");

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = import.meta.env.VITE_ELEMENT_PER_PAGE;
  const { setSelectedCategory, selectedCourseLevel } = useContext(DataContext);

  useEffect(() => {
    document.getElementById("root")?.classList.remove("custom-h");
    window.scrollTo(0, 0);
    setSelectedCategory("Accueil");
  }, []);

  /*useEffect(() => {
    axiosClient
      .get("/courses")
      .then((res) => setCourses(res.data))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);*/

  useEffect(() => {
    const uri = `/courses?page=${currentPage}&size=${pageSize}&sort=lastModifiedDate,desc${
      query ? "&name.contains=" + query : ""
    }${
        selectedCourseLevel ? "&levelId.equals=" + selectedCourseLevel.id : ""
    }`;
    axiosClient
      .get(uri)
      .then((res) => {
        setCourses(res.data);
        setTotalPages(res.headers["x-total-pages"]);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [query, currentPage, selectedCourseLevel]);

  return (
    <>
      {!isOnline && (
        <div className="grow  h-full overflow-y-auto bg-black">
          <OfflineComponent />
        </div>
      )}

      {isOnline && (
        <div className=" grow  bg-white pb-12">
          <div className="flex flex-row justify-between">
           <CourseLevelFilter/>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 pt-2">
            {/* When loading is true means data is not fetched yet meanwhile that time show Shimmer UI
             when data fetching is done means loading is false and that means we get recentCourses then show the data in the dom */}
            {loading
                ? Array(24)
                    .fill("")
                    .map((e, index) => {
                      return <ShimmerVideoCard key={index}/>;
                    })
              : courses?.map((course: CourseDTO) => {
                  return <CourseCard key={course.id} course={course} />;
                })}
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onSelectPage={(page: number) => setCurrentPage(page)}
          />
        </div>
      )}
    </>
  );
};

export default Home;
