
import "./style.css";
import { BiBowlingBall } from "react-icons/bi";
import { useEffect, useState } from "react";
import { GoCreditCard } from "react-icons/go";
import {PaymentData} from "@/generated";


export const PayWithVoucher = (props : any) => {

    const paymentData : PaymentData = props.paymentData;
    const sendPaymentRequest = props.sendPaymentRequest;
    const isWaitingPaymentResponse = props.isWaitingPaymentResponse;


    const [voucher, setVoucher] = useState<string>("")
    const [validVoucher, setValidVoucher,] = useState<string>("")


    useEffect(() => {
        if(voucherIsValid(voucher)){
            setValidVoucher(voucher);
        }else{
            setValidVoucher("")
        }
    }, [voucher])


const voucherIsValid =  (voucher : string)=>{
    return voucher.length > 7;
}

    return (
        <section className="max-w-xl mx-auto px-4 md:px-8">
            <div className="space-y-3 text-center">

                <GoCreditCard size={50} className="text-gray-300 mx-auto "/>

                <h3 className="text-3xl text-gray-800 font-bold">
                    Entrez un code d'activation
                </h3>
                <p className="text-gray-400 leading-relaxed">
                    Si vous détenez un code veuillez le fournir
                </p>
            </div>
            <div className="mt-6">
                <form
                    onSubmit={(e) => e.preventDefault()}
                    className="items-center justify-center sm:flex">
                    <input
                        value={voucher}
                        onChange={(e) => setVoucher(e.target.value)}
                        type="tel"
                        disabled={isWaitingPaymentResponse}
                        placeholder="555-555-555-555"
                        className={` ${isWaitingPaymentResponse ? "cursor-not-allowed" : ""} text-gray-500 w-full p-3 rounded-md border outline-none focus:border-orange-white`}
                    />

                    {!isWaitingPaymentResponse && validVoucher &&
                        <button onClick={() => sendPaymentRequest({...paymentData, voucher: validVoucher, paymentStatus:""})}
                                className=" flex flex-row gap-2 items-center w-full mt-3 px-5 py-3 rounded-md text-white bg-orange-white hover:bg-orange active:bg-orange duration-150 outline-none shadow-md focus:shadow-none focus:ring-2 ring-offset-2 ring-orange sm:mt-0 sm:ml-3 sm:w-auto"
                        >
                            Confirmer
                        </button>}

                    {!isWaitingPaymentResponse && !validVoucher && <button
                        className=" cursor-not-allowed bg-red-300 flex flex-row gap-2 items-center w-full mt-3 px-5 py-3 rounded-md text-white duration-150 outline-none shadow-md focus:shadow-none focus:ring-2 ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto"
                    >
                        Confirmer
                    </button>}


                    {isWaitingPaymentResponse && <button
                        className=" cursor-not-allowed bg-orange-white flex flex-row gap-2 items-center w-full mt-3 px-5 py-3 rounded-md text-white duration-150 outline-none shadow-md focus:shadow-none focus:ring-2 ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto"
                    >
                        Traitement
                        <BiBowlingBall color="white" size={25} className="loading-icon"/>

                    </button>}

                </form>

            </div>


        </section>
    )
}
