/* tslint:disable */
/* eslint-disable */
/**
 * Elimu API
 * Elimu API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { Plan } from './plan';

/**
 * 
 * @export
 * @interface PaymentData
 */
export interface PaymentData {
    /**
     * 
     * @type {Plan}
     * @memberof PaymentData
     */
    'membershipPlan'?: Plan;
    /**
     * 
     * @type {number}
     * @memberof PaymentData
     */
    'monthCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentData
     */
    'paymentMethod'?: PaymentDataPaymentMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentData
     */
    'paymentPhoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentData
     */
    'voucher'?: string;
}

export const PaymentDataPaymentMethodEnum = {
    Cash: 'CASH',
    Mpesa: 'MPESA',
    AirtelMoney: 'AIRTEL_MONEY',
    OrangeMoney: 'ORANGE_MONEY',
    BankDeposit: 'BANK_DEPOSIT',
    Voucher: 'VOUCHER'
} as const;

export type PaymentDataPaymentMethodEnum = typeof PaymentDataPaymentMethodEnum[keyof typeof PaymentDataPaymentMethodEnum];


