import React from "react";

export const Pagination = (props: {
  totalPages: number;
  currentPage: number;
  onSelectPage: any;
}) => {


  return (
    <div className="max-w-screen-xl mx-auto mt-12 px-4 text-gray-600 md:px-8">
      <div
        className="hidden items-center justify-between sm:flex"
        aria-label="Pagination"
      >
        <a
          href="#"
          className="hover:text-indigo-600 flex items-center gap-x-2"
          onClick={() =>
            props.onSelectPage(
              props.currentPage == 0 ? 0 : props.currentPage - 1
            )
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-5 h-5"
          >
            <path
              fillRule="evenodd"
              d="M18 10a.75.75 0 01-.75.75H4.66l2.1 1.95a.75.75 0 11-1.02 1.1l-3.5-3.25a.75.75 0 010-1.1l3.5-3.25a.75.75 0 111.02 1.1l-2.1 1.95h12.59A.75.75 0 0118 10z"
              clipRule="evenodd"
            />
          </svg>
          Précédent
        </a>

        <ul className="flex items-center gap-1">
          <li className="text-sm">
            <a
              href="#"
              onClick={() => props.onSelectPage(0)}
              aria-current={props.currentPage == 0 ? "page" : false}
              className={`px-3 py-2 rounded-lg duration-150 hover:text-indigo-600 hover:bg-indigo-50 ${
                props.currentPage == 0
                  ? "bg-indigo-50 text-indigo-600 font-medium"
                  : ""
              }`}
            >
              1
            </a>
          </li>
          {props.currentPage >= 3 && (
            <li className="text-sm">
              <div>...</div>
            </li>
          )}
          {props.currentPage < 3 &&  props.totalPages > 1 &&(
            <li className="text-sm">
              <a
                href="#"
                onClick={() => props.onSelectPage(1)}
                aria-current={props.currentPage == 1 ? "page" : false}
                className={`px-3 py-2 rounded-lg duration-150 hover:text-indigo-600 hover:bg-indigo-50 ${
                  props.currentPage == 1
                    ? "bg-indigo-50 text-indigo-600 font-medium"
                    : ""
                }`}
              >
                2
              </a>
            </li>
          )}
          {props.currentPage < 3 &&  props.totalPages > 2 && (
            <li className="text-sm">
              <a
                href="#"
                onClick={() => props.onSelectPage(2)}
                aria-current={props.currentPage == 2 ? "page" : false}
                className={`px-3 py-2 rounded-lg duration-150 hover:text-indigo-600 hover:bg-indigo-50 ${
                  props.currentPage == 2
                    ? "bg-indigo-50 text-indigo-600 font-medium"
                    : ""
                }`}
              >
                3
              </a>
            </li>
          )}
          {props.currentPage >= 3 && (
            <li className="text-sm">
              <a
                href="#"
                aria-current="page"
                className="px-3 py-2 rounded-lg duration-150 hover:text-indigo-600 hover:bg-indigo-50
                bg-indigo-50 text-indigo-600 font-medium"
              >
                {props.currentPage + 1}
              </a>
            </li>
          )}

          {props.currentPage+1 < props.totalPages &&  props.totalPages >3 &&(
            <li className="text-sm">
              <div>...</div>
            </li>
          )}
          {props.currentPage+1 < props.totalPages  && props.totalPages >3 &&(
            <li className="text-sm">
              <div className="px-3 py-2">{props.totalPages}</div>
            </li>
          )}
        </ul>
        <a
          href="#"
          onClick={() =>
            props.onSelectPage(
              props.currentPage == props.totalPages - 1
                ? props.currentPage
                : props.currentPage + 1
            )
          }
          className="hover:text-indigo-600 flex items-center gap-x-2"
        >
          suivant
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-5 h-5"
          >
            <path
              fillRule="evenodd"
              d="M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z"
              clipRule="evenodd"
            />
          </svg>
        </a>
      </div>
      {/* On mobile version */}
      <div className="flex items-center justify-between text-sm text-gray-600 font-medium sm:hidden">
        <a
          href="#"
          className="px-4 py-2 border rounded-lg duration-150 hover:bg-gray-50"
          onClick={() =>
            props.onSelectPage(
              props.currentPage == 0 ? 0 : props.currentPage - 1
            )
          }
        >
          Précédent
        </a>
        <div className="font-medium">
          Page {props.currentPage + 1} de {props.totalPages}
        </div>
        <a
          href="#"
          onClick={() =>
            props.onSelectPage(
              props.currentPage == props.totalPages - 1
                ? props.currentPage
                : props.currentPage + 1
            )
          }
          className="px-4 py-2 border rounded-lg duration-150 hover:bg-gray-50"
        >
          Suivant
        </a>
      </div>
    </div>
  );
};
