import React from "react";
import { MdSettingsInputAntenna } from "react-icons/md";
import { Link } from "react-router-dom";

export const ActivateMembership = () => {
  return (
    <>
      <div className="container flex flex-col items-center justify-center px-5 my-20 2xl:my-44 mx-auto space-y-8  text-center sm:max-w-md">
        <MdSettingsInputAntenna className="text-slate-500" size={50} />

        <p className="text-xl">
          Veuillez Activer un abonnement pour commencer à visionner
        </p>
        <div className="flex-1 flex items-end">
          <Link
            to="/paiement"
            className="px-3 py-3 rounded-lg w-full font-semibold text-sm duration-150 text-white bg-orange-white hover:bg-orange active:bg-orange"
          >
            Activer un abonnement
          </Link>
        </div>
      </div>
    </>
  );
};
