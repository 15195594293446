import {Plan} from "../../models/Plan.ts";

export const Pricing = ({ step, setStep, paymentData, setPaymentData }) => {

    const selectPlan = (plan : Plan) => {
        setPaymentData({membershipPlan: plan })
        setStep(step + 1)
    }

    const availablePlans : Plan[] = [
        {
            name: "Standard",
            code: "STANDARD",
            price: 10,
            features: [
                "Contenu video 24/7",
                "Qualité jusqu'à 1080P​",
                "Lecture Hors ligne",
                "2 Appareils connectés",

            ],
        },
        {
            name: "Premium",
            code: "PREMIUM",
            price: 15,
            features: [
                "Contenu video 24/7",
                "Qualité jusqu'à 1080P​",
                "Lecture Hors ligne",
                "2 Appareils connectés",
                "Tutorat à domicile",
            ],
        }

    ];


    return (
        <div className="max-w-screen-md mx-auto px-4 text-gray-600 md:px-8">
            <div className='relative max-w-xl mx-auto sm:text-center'>
                <h3 className='text-gray-800 text-3xl font-semibold sm:text-4xl'>
                    Veuillez choisir un abonnement
                </h3>
                <div className='mt-3 max-w-xl'>
                    <p>
                        Vous Choisirez par la suite le nombre de mois
                    </p>
                </div>
            </div>


            <div className='mt-10 space-y-6 justify-center gap-6 sm:grid sm:grid-cols-2 sm:space-y-0 lg:grid-cols-2'>
                {
                    availablePlans.map((item, idx) => (
                        <div key={idx} className='relative flex-1 flex items-stretch flex-col p-8 rounded-xl border-2'>
                            <div>
                                <span className='text-orange text-2xl font-bold'>
                                    {item.name}
                                </span>
                                <div className='mt-4 text-gray-800 text-3xl font-semibold'>
                                    ${item.price} <span className="text-xl text-gray-600 font-normal">/mois</span>
                                </div>
                            </div>
                            <ul className='py-8 space-y-3'>
                                {
                                    item.features.map((featureItem, idx) => (
                                        <li key={idx} className='flex items-center gap-5'>
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                className='h-5 w-5 text-dark'
                                                viewBox='0 0 20 20'
                                                fill='currentColor'>
                                                <path
                                                    fillRule='evenodd'
                                                    d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                                                    clipRule='evenodd'></path>
                                            </svg>
                                            {featureItem}
                                        </li>
                                    ))
                                }
                            </ul>
                            <div className="flex-1 flex items-end">
                                <button onClick={() => selectPlan(item)} className='px-3 py-3 rounded-lg w-full font-semibold text-sm duration-150 text-white bg-orange-white hover:bg-orange active:bg-orange'>
                                    Choisir
                                </button>
                            </div>
                        </div>
                    ))
                }
            </div>


        </div>
    );
};
