import axios from 'axios';
import { appRouter } from '../App';
import { authenticate } from '../context/AuthContext';

const axiosClient = axios.create({
   baseURL: import.meta.env.VITE_API_URL,
   headers: {
      "Content-Type": "application/json",
      X_CLIENT_ID: import.meta.env.VITE_X_CLIENT_ID,
      X_CLIENT_VERSION: import.meta.env.VITE_X_CLIENT_VERSION,
   }
});

axiosClient.interceptors.response.use(response => {
   return response;
}, error => {
   if (error.response?.status === 401){
      authenticate();
   }else {
      handleHttpErrorResponseBody(error?.response?.data);
   }
   return Promise.reject(error);
});

const handleHttpErrorResponseBody = (responseBody) =>{
   if (responseBody?.status === 403 && responseBody?.detail?.includes("REQUIRE_ACTIVE_MEMBERSHIP")) {
      console.log("no active membership!!!")
      appRouter.navigate("/abonnement")
   }else{
      console.log("unknown error "+responseBody.status)
      //authenticate();
   }
}

export { axiosClient, handleHttpErrorResponseBody }
