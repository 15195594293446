import { createContext, useEffect, useState } from 'react'
import Keycloak, {KeycloakConfig, KeycloakInitOptions} from 'keycloak-js';
import { axiosClient } from '@/utils/HttpClient';
import {AdminUserDTO} from "@/generated";

const MOBILE_SSO_CLIENT_ID = window.sessionStorage.getItem("MOBILE_WEBVIEW_SSO_CLIENT_ID") // will be provided in the context of mobile app webview

const config : KeycloakConfig = {
  url: import.meta.env.VITE_SSO_HOST,
  realm: import.meta.env.VITE_SSO_REALM,
  clientId: MOBILE_SSO_CLIENT_ID || import.meta.env.VITE_SSO_CLIENT_ID,
}
const initOptions : KeycloakInitOptions = {
  onLoad: 'check-sso', // Supported values: 'check-sso' , 'login-required',
  //silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
  checkLoginIframe: false,
  //checkLoginIframeInterval: 5,
  enableLogging: import.meta.env.VITE_SSO_CONSOLE_DEBUG,
  pkceMethod: 'S256',
  token: window.sessionStorage.getItem("MOBILE_WEBVIEW_ACCESS_TOKEN") as string, // will be provided in the context of mobile app webview
  refreshToken: window.sessionStorage.getItem("MOBILE_WEBVIEW_REFRESH_TOKEN") as string // will be provided in the context of mobile app webview
}

//alert("storage accessToken : "+initOptions.token+"\n \n \n refreshToken : "+initOptions.refreshToken+"\n \n"+config.clientId)

const kc = new Keycloak(config);

export const OAuthContext = createContext();
export let authenticate;
export const AuthContext = (props) => {

  const [ssoInitialized, setSSOInitialized] = useState(false)
  const [authenticatedUser, setAuthenticatedUser] = useState<AdminUserDTO>()
  useEffect(() => {
    initKeycloak();
  }, [])

  const initKeycloak = () => {
    //console.log("keycloak initialization...");
    kc.init(initOptions).then((auth) => {
      if (!auth) {
        //window.location.reload();
      } else {

        addTokenToAxiosClientHeader()
        fetchAuthenticatedUser()

        kc.onTokenExpired = () => {
          //console.log('token expired');

          kc.updateToken(30).then(() => {
            //console.log('successfully got a new token');
            addTokenToAxiosClientHeader()
          })
        }

        /* setTimeout(() => {
           keycloak.updateToken(70).success((refreshed) => {
               if (refreshed) {
                   console.debug('Token refreshed' + refreshed);
               } else {
                   console.warn('Token not refreshed, valid for '
                       + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
               }
           }).error(() => {
               console.error('Failed to refresh token');
           });


       }, 60000)

        const logout = () => {
          kc.logout();
        };*/

      }
    }, () => {
      /* Notify the user if necessary */
      console.error("Authentication Failed");
    }).finally(() => setSSOInitialized(true))
  }
  /**
   * getting the User connected from the backend api. A token is supposed to be added to the headers for it to work
   * Its also help to sync the user from Identity provider (keycloak for our case) with the app user
   * Of course, we could also get user details from the token itself, so this mostly to make sure the app db is in sync with the IDP
   */
  const fetchAuthenticatedUser = () =>{
    axiosClient.get('/account').then(res => {
      setAuthenticatedUser(res.data);
    })
  }

  const isAuthenticated = () => {
    return !!kc.token;
  }

  const addTokenToAxiosClientHeader = () => {
    axiosClient.defaults.headers[
      "Authorization"
    ] = `Bearer ${kc.token}`;
  }


  const getBearerToken = () => {
    return kc.token;
  }

  authenticate = () => {
    //console.log("authenticating ...");
    kc.login().then(() => {
      console.log("redirected");
      addTokenToAxiosClientHeader()
      fetchAuthenticatedUser()
    })
  }
  const logout = () => {
    //console.log("logout ...");
    kc.logout().then(() => {
      //console.log("success");
    })
  }

  const hasAnyAuthority = (roles) => roles.some((role) => kc.hasRealmRole(role));
  const getUsername = () => kc.tokenParsed?.preferred_username;


  return (
    <OAuthContext.Provider
      value={{
        ssoInitialized,
        getUsername,
        hasAnyAuthority,
        authenticate,
        getBearerToken,
        isAuthenticated,
        addTokenToAxiosClientHeader,
        authenticatedUser,
        logout
      }}
    >
      {props.children}
    </OAuthContext.Provider>
  );
}
