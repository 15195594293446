import PropTypes from 'prop-types'
import {useEffect, useState} from "react";


export interface AlertType{
    title : string;
    content : string;
    type : "success"|"infos"|"danger"|"warning";
}

export const Alert = (props:any) => {
    const [type, setType] = useState(props.type)
    const [content, setContent] = useState(props.content)
    const [title, setTitle] = useState(props.title)

    useEffect(() => {
        setType(props.type)
        setTitle(props.title)
        setContent(props.content)
    }, [props]);

    const resetAlert = ()=>{
       setType("");
    }
    return (<>

        {type =="success" &&<div className="mt-6 mx-4 px-4 rounded-md border-l-4 border-green-500 bg-green-50 md:max-w-xl md:mx-auto md:px-8">
            <div className="flex justify-between py-3">
                <div className="flex">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 rounded-full text-green-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                        </svg>
                    </div>
                    <div className="self-center ml-3">
                        <span className="text-green-600 font-semibold">
                            {title}
                        </span>
                        <p className="text-green-600 mt-1">
                            {content}
                        </p>
                    </div>
                </div>
                <button onClick={()=>resetAlert()} className="self-start text-green-500">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                </button>
            </div>
        </div>}


        {type =="danger" && <div className="mt-6 mx-4 px-4 rounded-md border-l-4 border-red-500 bg-red-50 md:max-w-xl md:mx-auto md:px-8">
            <div className="flex justify-between py-3">
                <div className="flex">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                        </svg>
                    </div>
                    <div className="self-center ml-3">
                        <span className="text-red-600 font-semibold">
                        {title}
                        </span>
                        <p className="text-red-600 mt-1">
                        {content}
                        </p>
                    </div>
                </div>
                <button onClick={()=>resetAlert()} className="self-start text-red-500">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                </button>
            </div>
        </div>}

        {type =="infos" &&<div className="mt-6 mx-4 px-4 rounded-md border-l-4 border-blue-500 bg-blue-50  md:max-w-xl md:mx-auto md:px-8">
            <div className="flex justify-between py-3">
                <div className="flex">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-blue-400" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                        </svg>
                    </div>
                    <div className="self-center ml-3">
                        <span className="text-blue-500 font-semibold">
                        {title}
                        </span>
                        <p className="text-blue-500 mt-1">
                        {content}
                        </p>
                    </div>
                </div>
                <button onClick={()=>resetAlert()} className="self-start text-blue-500">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                </button>
            </div>
        </div>}


        {type =="warning" &&<div className="mt-6 mx-4 px-4 rounded-md border-l-4 border-yellow-500 bg-yellow-50  md:max-w-xl md:mx-auto md:px-8">
            <div className="flex justify-between py-3">
                <div className="flex">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-yellow-600" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                        </svg>
                    </div>
                    <div className="self-center ml-3">
                        <span className="text-yellow-600 font-semibold">
                        {title}
                        </span>
                        <p className="text-yellow-600 mt-1">
                        {content}
                        </p>
                    </div>
                </div>
                <button  onClick={()=>resetAlert()} className="self-start text-yellow-600">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                </button>
            </div>
        </div>}

    </>
    )
}

Alert.propTypes = {
    type: PropTypes.oneOf(['success', 'infos', 'danger', 'warning']).isRequired,
    title : PropTypes.string.isRequired,
    content : PropTypes.string.isRequired
}
export default Alert;
