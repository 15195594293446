import ShimmerSuggestionVideoCard from "@/shared/ShimmerSuggestionVideoCard";
import {Skeleton} from "@/components/ui/skeleton"
import React from "react";


const ViewCourseSkeletonLoader = () => {


    return (
        <div className="w-full max-w-[1500px] flex flex-col lg:flex-row ">
            <div className="flex flex-col lg:w-[calc(100%-350px)] xl:w-[calc(100%-400px)] mx-3 py-2  overflow-y-auto">

                <div className="bg-slate-500/[0.2] w-full h-[200px] sm:h-[400px] lg:h-5/6 rounded-xl">
                </div>


                <div className="text-black dark:text-white font-bold text-sm md:text-xl mt-4 line-clamp-2">
                    <span className="mt-2 flex bg-slate-500/[0.2] py-2  md:px-12 "/>
                </div>
                <div className="text-black dark:text-white text-xs md:text-lg mt-2 line-clamp-2">
                    <span className="mt-2 flex bg-slate-500/[0.2] py-2  md:px-12 "/>
                </div>


            </div>

            <div className="flex flex-col py-2 mx-3 overflow-y-auto lg:w-[350px] xl:w-[400px]">
                {Array(4)
                    .fill("")
                    .map((e, index) => {
                        return <ShimmerSuggestionVideoCard key={index}/>;
                    })}

            </div>
        </div>
    );
};

export default ViewCourseSkeletonLoader;
