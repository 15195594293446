import { useState, useEffect } from "react";
import UseOnline from "../utils/UseOnline";
import OfflineComponent from "../shared/OfflineComponent";
import ShimmerSearchResultVideoCard from "../shared/ShimmerSearchResultVideoCard";
import { axiosClient } from "../utils/HttpClient";
import { CourseWatched } from "../shared/models/CourseWatched";
import CourseWatchedCard from "../components/CourseWatchedCard";
import EmptyDataComponent from "../shared/EmptyDataComponent";

const History = () => {
  const [coursesWatched, setCoursesWatched] = useState<Array<CourseWatched>>([]);
  const [loading, setLoading] = useState(true);

  const isOnline = UseOnline();


  useEffect(() => {
    axiosClient
      .get("/course-watcheds")
      .then((res) => setCoursesWatched(res.data))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);
  

  return (
    <>
      {!isOnline && (
        <div className="grow w-[calc(100%-240px)] h-full overflow-y-auto bg-black">
          <OfflineComponent />
        </div>
      )}
      {isOnline && (
        <div className="grow w-[calc(100%-240px)] h-full overflow-y-auto bg-white dark:bg-black">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 gap-4 p-5">
            {/* When result is blank means data is not fetched yet meanwhile that time show Shimmer UI 
             when data fetching is done that means we get result data then show the data in the dom*/}
            {loading
              ? Array(20)
                .fill("")
                .map((e, index) => {
                  return <ShimmerSearchResultVideoCard key={index} />;
                })
              : coursesWatched.length ? coursesWatched?.map((item : CourseWatched) => {
                return (
                  <CourseWatchedCard key={item.id} courseWatched={item}/>
                );
              }): <EmptyDataComponent/>}
          </div>
        </div>
      )}
    </>
  );
};

export default History;
