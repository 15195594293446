import React, {useEffect, useState} from "react";
import {PaymentMethod} from "@/components/payment-wizard/PaymentMethod";
import {PaymentHeader} from "@/components/payment-wizard/PaymentHeader";
import {PaymentFooter} from "@/components/payment-wizard/PaymentFooter";
import {Pricing} from "@/components/payment-wizard/Pricing";
import {IoArrowBack} from "react-icons/io5";
import {Alert, AlertType} from "@/shared/Alert";
import {Link} from "react-router-dom";
import {PayWithMobileMoney} from "@/components/payment-wizard/PayWithMobileMoney";
import {PayWithVoucher} from "@/components/payment-wizard/PayWithVoucher";
import {axiosClient} from "@/utils/HttpClient";
import dayjs from "dayjs";
import {MembershipValidity} from "@/components/payment-wizard/MembershipValidity";
import {PaymentCategory} from "@/components/payment-wizard/PaymentCategory";
import {
    MembershipDTO,
    MembershipDTOPaymentRequestStatusEnum,
    PaymentData,
    PaymentDataPaymentMethodEnum
} from "@/generated";
import { Button } from "@/components/ui/button"
import {AxiosError, AxiosResponse} from "axios";


export const PaymentWizard = () => {
    const [step, setStep] = useState(0);
    const CHECK_STATUS_INTERVAL = 3000;
    const CHECK_STATUS_MAX_COUNT = 5;
    const CHECK_STATUS_COUNT_TO_SHOW_WARNING = 3;
    const [paymentData, setPaymentData] = useState<PaymentData>();
    const [membership, setMembership] = useState<MembershipDTO>();
    const [isWaitingPaymentResponse, setIsWaitingPaymentResponse] = useState(false)

    const [alert, setAlert] = useState<AlertType>();
    const [intervalId, setIntervalId] = useState<any>()
    const [checkStatusCount, setCheckStatusCount] = useState(0)

    const paymentDataReadyTobeSent = ()=> {
        return paymentData?.voucher && step == 1 || paymentData?.paymentPhoneNumber && step == 4;
    }

    const sendPaymentRequest= (paymentData : PaymentData)=>{
        setPaymentData(paymentData);
    }

    useEffect(() => {
       /* console.log("cool")
        console.log(!membership)
        console.log(!isWaitingPaymentResponse)
        console.log(paymentDataReadyTobeSent())
        console.log(checkStatusCount)*/

        if (!membership && !isWaitingPaymentResponse && paymentDataReadyTobeSent()) {
            setAlert(undefined);
            setIsWaitingPaymentResponse(true)
            setCheckStatusCount(0)
            axiosClient
                .post("/memberships/payment", paymentData)
                .then(handleSuccess)
                .catch(handleError);
        } else if (membership?.paymentRequestStatus === MembershipDTOPaymentRequestStatusEnum.Submitted) {
            //refresh payment status periodically after the payment request is sent
                setAlert(undefined);
                setIntervalId(setInterval(() => {
                    refreshMembershipPaymentStatus();
                }, CHECK_STATUS_INTERVAL));
        }
        return () => clearInterval(intervalId);
    }, [paymentData,membership]);

    useEffect(() => {
        if (checkStatusCount === CHECK_STATUS_COUNT_TO_SHOW_WARNING){
            setAlert({
                content :`Veuillez confirmer votre pin ${getNumberType()} sur votre téléphone lorsqu'il vous est démandé.`,
                title :"Aviez vous confirmé votre PIN ?",
                type :"warning"
            })
        }
        if (checkStatusCount >= CHECK_STATUS_MAX_COUNT){
            clearInterval(intervalId)
            setIsWaitingPaymentResponse(false)
            setMembership(undefined)
            setPaymentData({...paymentData, paymentPhoneNumber:""})
        }
    }, [checkStatusCount]);

    const refreshMembershipPaymentStatus = () => {
            axiosClient
                .get("/memberships/payment/status/" + membership?.id)
                .then(handleSuccess)
                .catch(handleError)
                .finally(()=>setCheckStatusCount(previousCheckStatusCount => previousCheckStatusCount+1))
    };

    function getNumberType() {
        return paymentData?.paymentMethod === PaymentDataPaymentMethodEnum.AirtelMoney
            ? "Airtel Money"
            : paymentData?.paymentMethod === PaymentDataPaymentMethodEnum.Mpesa
                ? "Mpesa"
                : "Orange Money";
    }

    const handleError = (error: AxiosError) => {
        clearInterval(intervalId);
        setIsWaitingPaymentResponse(false);
        setMembership(undefined)
        if (
            error.response?.status === 400 &&
            error.response?.data?.detail?.includes("TOO_MANY_ATTEMPTS")
        ) {
            setAlert({
                type: "danger",
                title: "Code d'activation erroné",
                content: "Vous avez dépassé le nombre limite de tentatives. Veuillez réessayer après 24h",
            });
        } else if (
            error.response?.status === 400 &&
            error.response?.data?.detail?.includes("INVALID_VOUCHER")
        ) {
            setAlert({
                type: "warning",
                title: "Code d'activation erroné",
                content:"Le code d'activation n'est pas valide. Notez que 3 tentatives erronées bloqueront votre compte",
            });
        } else if (
            error.response?.status === 400 &&
            error.response?.data?.detail?.includes("MEMBERSHIP_ALREADY_ACTIVE")
        ) {
            setAlert({
                type: "warning",
                title: "Un abonnement est déja actif",
                content:
                    "Vous avez déja un abonnement en cours. Veuillez attendre l'expiration ou contacter le service client à contact@elimu.cd",
            });
        } else if (
            error.response?.status === 400 &&
            error.response?.data?.detail?.includes("BAD_MSISDN")
        ) {
            const numberType = getNumberType();
            setAlert({
                type: "danger",
                title: `Numéro ${numberType} incorrecte`,
                content: `Vous avez introduit un numéro ${numberType} qui ne semble pas être valide. Veuillez revérifier.`,
            });
        } else {
            setAlert({
                type: "danger",
                title: "Une erreure s'est produite",
                content:
                    "Nous sommes désolé. Nous n'avons pas pu traiter votre requête. Si l'erreur persite, vous pouvez contacter le service client à contact@elimu.cd",
            });
        }
    };

    const handleSuccess = (res: AxiosResponse<MembershipDTO>) => {
        if (res.data.paymentRequestStatus !== membership?.paymentRequestStatus){
            setMembership(res.data);
        }
        if (
            res.data?.paymentRequestStatus === MembershipDTOPaymentRequestStatusEnum.Completed
        ) {
            setIsWaitingPaymentResponse(false)
            setAlert({
                type: "success",
                title: "Activation Réussie",
                content: `Félicitations. Votre nouvel abonnement est actif pour ${
                    res.data?.validityInMonths
                } mois, du ${dayjs(res.data?.membershipStartAt).format(
                    "DD/MM/YYYY"
                )} au ${dayjs(res.data?.membershipEndAt).format(
                    "DD/MM/YYYY"
                )}. Vous pouvez maintenant accéder au contenu`,
            });
        }else if (
            res.data?.paymentRequestStatus === MembershipDTOPaymentRequestStatusEnum.Cancelled
        ) {
            setIsWaitingPaymentResponse(false)
            setAlert({
                type: "danger",
                title: "La transaction n'a pas abouti",
                content: `Cela arrive souvent si vous n'avez pas de balance suffisante ou que vous n'aviez pas validé votre PIN. N'hesitez pas à contacter le support client à contact@elimu.cd`,
            });
        }
    };

    const goBack = () => {
        setStep(step == 0 ? 0 : step - 1);
        setPaymentData({...paymentData, paymentPhoneNumber: "", voucher:""});
        setAlert(undefined);
        setMembership(undefined)
    };
    //header will be hidden on mobile webview browser
    const disableHeader = window.sessionStorage.getItem(
        "MOBILE_WEBVIEW_DISABLE_HEADER"
    );
    return (
        <div className="flex flex-col h-screen">
            {!disableHeader && <PaymentHeader/>}
            <div className="pt-8 mb-auto">
                {/* <div className="mb-8">
                    <Wizard itemsArray={["Choix abonnement", "Methode de paiement", "Confirmation"]} currentStep={step + 1} />

    </div>*/}

                {step == 0 && (
                    <PaymentCategory
                        step={step}
                        setStep={setStep}
                        paymentData={paymentData}
                        setPaymentData={setPaymentData}
                    />
                )}
                {step == 1 && paymentData?.paymentMethod === PaymentDataPaymentMethodEnum.Voucher && (
                    <PayWithVoucher
                        step={step}
                        setStep={setStep}
                        paymentData={paymentData}
                        sendPaymentRequest={sendPaymentRequest}
                        isWaitingPaymentResponse={isWaitingPaymentResponse}
                    />
                )}
                {step == 1 && paymentData?.paymentMethod !== PaymentDataPaymentMethodEnum.Voucher &&(
                    <Pricing
                        step={step}
                        setStep={setStep}
                        paymentData={paymentData}
                        setPaymentData={setPaymentData}
                    />
                )}
                {step == 2 && (
                    <MembershipValidity
                        step={step}
                        setStep={setStep}
                        paymentData={paymentData}
                        setPaymentData={setPaymentData}
                    />
                )}
                {step == 3 && (
                    <PaymentMethod
                        step={step}
                        setStep={setStep}
                        paymentData={paymentData}
                        setPaymentData={setPaymentData}
                    />
                )}
                {step == 4 &&
                    paymentData?.paymentMethod !== PaymentDataPaymentMethodEnum.Voucher && (
                        <PayWithMobileMoney
                            step={step}
                            setStep={setStep}
                            paymentData={paymentData}
                            sendPaymentRequest={sendPaymentRequest}
                            membership={membership}
                            isWaitingPaymentResponse={isWaitingPaymentResponse}
                        />
                    )}

                {alert && (
                    <Alert
                        content={alert.content}
                        title={alert.title}
                        type={alert.type}
                    />
                )}

                {false && (
                    <>
                        <Alert
                            content="La transaction est inhabituellement longue. Vous pouvez contacter le service client à contact@elimu.cd"
                            title="Transaction en cours"
                            type={"warning"}
                        />

                        <Alert
                            content="Votre paiement n'a pas abouti. Rassurez-vous d'avoir suffisament de fond et d'accepter le paiement le paiement sur votre téléphone"
                            title="Paiement refusé"
                            type="danger"
                        />

                        <Alert
                            content="Merci pour votre paiement. Vous pouvez maintenant accéder au contenu"
                            title="Paiement Reçu"
                            type="success"
                        />
                    </>
                )}

                {membership?.paymentRequestStatus === MembershipDTOPaymentRequestStatusEnum.Completed && (
                    <div className="text-center">
                        <Link
                            to={"/cours"}
                            className="mt-8  text-indigo-600 font-medium inline-flex items-center"
                        >
                            Accéder à votre compte maintenant
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 ml-1 duration-150"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M13 7l5 5m0 0l-5 5m5-5H6"
                                />
                            </svg>
                        </Link>
                    </div>
                )}

                {step > 0  && (
                    <div className="text-center">
                        <Button
                            disabled={isWaitingPaymentResponse}
                            variant="link"
                            onClick={() => goBack()}
                            className="mt-8 text-orange font-medium inline-flex items-center">
                            <IoArrowBack className="mr-2"/> <span className="font-bold">Retourner</span>
                        </Button>
                    </div>
                )}
            </div>

            <PaymentFooter/>
        </div>
    );
};

export default PaymentWizard;
/*
const Wizard = ({ itemsArray , currentStep }) => {
    useEffect(() => {
        setStep({
            stepsItems: itemsArray,
            currentStep: currentStep
        })
    }, [currentStep, itemsArray])


    const [steps, setStep] = useState({
        stepsItems: itemsArray,
        currentStep: currentStep
    })

    return (
        <div className="hidden lg:block max-w-3xl mx-auto px-4 md:px-0">
            <ul aria-label="Steps" className="items-center text-gray-600 font-medium md:flex">
                {steps.stepsItems.map((item, idx) => (
                    <li aria-current={steps.currentStep == idx + 1 ? "step" : false} className="flex-1 last:flex-none flex gap-x-2 md:items-center">
                        <div className="flex items-center flex-col gap-x-2">
                            <div className={`w-8 h-8 rounded-full border-2 flex-none flex items-center justify-center ${steps.currentStep > idx + 1 ? "bg-indigo-600 border-indigo-600" : "" || steps.currentStep == idx + 1 ? "border-indigo-600" : ""}`}>
                                <span className={` ${steps.currentStep > idx + 1 ? "hidden" : "" || steps.currentStep == idx + 1 ? "text-indigo-600" : ""}`}>
                                    {idx + 1}
                                </span>
                                {
                                    steps.currentStep > idx + 1 ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-white">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                        </svg>
                                    ) : ""
                                }
                            </div>
                            <hr className={`h-12 border md:hidden ${idx + 1 == steps.stepsItems.length ? "hidden" : "" || steps.currentStep > idx + 1 ? "border-indigo-600" : ""}`} />
                        </div>
                        <div className="h-8 flex items-center md:h-auto">
                            <h3 className={`text-sm ${steps.currentStep == idx + 1 ? "text-indigo-600" : ""}`}>
                                {item}
                            </h3>
                        </div>
                        <hr className={`hidden mr-2 w-full border md:block ${idx + 1 == steps.stepsItems.length ? "hidden" : "" || steps.currentStep > idx + 1 ? "border-indigo-600" : ""}`} />
                    </li>
                ))}
            </ul>
        </div>
    )
}*/
