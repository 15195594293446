import React from "react";
import { RiWifiOffLine } from "react-icons/ri";

const OfflineComponent = () => {
  return (
    <section className="flex items-center h-full md:h-screen lg:h-screen sm:p-16 bg-white text-black">
      <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8 space-y-8 text-center sm:max-w-md">
      <RiWifiOffLine className="text-slate-500" size={50}/>

        <p className="text-xl">
          Vous semblez être hors ligne
        </p>
        <p>Essayer de:</p>
        <ul>
          <li>
            Vérifier le <span className="text-purple-500">Mode Avion</span>
          </li>
          <li>
            Vérifier l'accès aux <span className="text-purple-500">Données mobiles </span> ou {" "}
            <span className="text-purple-500">Wi-Fi</span>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default OfflineComponent;
