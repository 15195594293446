import React from "react";
import { FaFolderOpen } from "react-icons/fa";

const EmptyDataComponent = () => {
  return (
    <section className="flex items-center h-full md:h-screen lg:h-screen sm:p-16 bg-white text-black">
      <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8 space-y-8 text-center sm:max-w-md">
        <FaFolderOpen className="text-slate-500" size={50}/>
        <p className="text-2xl">
          Aucune donnée trouvée
        </p>
        
      </div>
    </section>
  );
};

export default EmptyDataComponent;
