import React from "react";

const ShimmerSearchResultVideoCard = () => {
  return (
    <>
      {
        //For smal screens
      }
      <div className="flex  lg:hidden flex-col mb-8">
        <div className=" h-48 md:h-40overflow-hidden bg-slate-500/[0.2]">
          {/*Thumbnail*/}
        </div>

        <div className="flex mt-3">
          <div className="flex items-start">
            <div className="flex h-9 w-9 rounded-full bg-slate-500/[0.2]">
              {/*Avatar*/}
            </div>
          </div>

          <div className="flex flex-col ml-3  ">
            <span className="bg-slate-500/[0.2] py-2 px-32 md:px-[90px]">
              {/*Video Title*/}
            </span>

            <span className="mt-2 flex bg-slate-500/[0.2] py-2 px-32 md:px-[90px]">
              {/*Author Details*/}
            </span>
          </div>
        </div>
      </div>


      {
        //For large screens lg
      }
      <div className="hidden lg:flex flex-col md:flex-row mb-8 md:mb-3 md:p-4">
        <div className="relative flex shrink-0 lg:w-96 lg:h-48 bg-slate-500/[0.2]">
          {/*Thumbnail*/}
        </div>

        <div className="flex flex-col ml-4 md:ml-6 mt-4 md:mt-0">
          <span className="px-32 md:px-60 py-3 md:py-4 mb-1 bg-slate-500/[0.2]">
            {/*Video Title*/}
          </span>

          <span className="md:pr-24 md:my-4 px-20 py-2 md:py-6 mb-1 lg:mb-8 bg-slate-500/[0.2]">
            {/* Video Description */}
          </span>

          <div className=" hidden md:flex mb-2 px-10 py-2  bg-slate-500/[0.2]">
            {/* views and Date */}
          </div>

          <div className="hidden md:flex items-center">
            <div className="flex items-start mr-3">
              <div className="flex h-9 w-9 rounded-full overflow-hidden bg-slate-500/[0.2]">
                {/* Avatar */}
              </div>
            </div>

            <div className="flex flex-col">
              <span className="mt-2 flex px-16 py-2  bg-slate-500/[0.2]">
                {/* Channel Name */}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShimmerSearchResultVideoCard;
