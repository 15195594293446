import { abbreviateNumber } from "js-abbreviation-number";
import { Link } from "react-router-dom";
import { BsFillCheckCircleFill } from "react-icons/bs";
import VideoLength from "../shared/VideoLength";
import { CourseWatched } from "../shared/models/CourseWatched";

const CourseWatchedCard = (props: any) => {
  let courseWatched: CourseWatched = props.courseWatched;
  return (
    <Link to={`/course/${courseWatched.course?.id}`}>
      <div className="flex flex-col lg:flex-row mb-2 lg:hover:bg-black/[0.1] dark:lg:hover:bg-white/[0.1] ">
        <div className="relative shrink-0 lg:w-96 lg:h-48 bg-slate-800 overflow-hidden">
          <img
            src={courseWatched.course?.introductionVideo?.thumbnail?.fileName}
            alt="thumbnails"
            className="h-auto w-full"
          />
         {courseWatched.course?.durationSec && <VideoLength time={courseWatched.course?.durationSec} />}
        </div>

        <div className=" flex text-black dark:text-white mt-3">
          {/* for smal screen only */}
          <div className="lg:hidden items-start">
            <div className="flex h-9 w-9 rounded-full overflow-hidden">
              <img
                src={
                  "data:image/svg+xml;charset=utf-8," +
                  courseWatched.course?.category?.icon
                }
                alt="Avatar"
                className="h-full w-full object-cover"
              />
            </div>
          </div>
          {/*all screens */}

          <div className="flex flex-col ml-3 overflow-hidden">
            <span className="text-sm lg:text-lg font-semibold line-clamp-2 text-black dark:text-white">
              {courseWatched.course?.title}
            </span>

            <span className="text-[12px] font-semibold mt-2 text-black/[0.7] dark:text-white/[0.7] flex items-center">
              {courseWatched.course?.category?.name}
              {true && (
                <BsFillCheckCircleFill className="text-black/[0.5] dark:text-white/[0.5] text-[12px] ml-1" />
              )}
            </span>

            <div className="flex text-[12px] font-semibold text-black/[0.7] dark:text-white/[0.7] truncate overflow-hidden">
              <span>{`${abbreviateNumber(
                courseWatched.course?.viewCount as number,
                2
              )} views`}</span>
              <span className="flex  text-[24px] leading-none font-bold text-black/[0.7] dark:text-white/[0.7] relative top-[-10px] mx-1">
                .
              </span>
              <span className="truncate">{'createdAt'}</span>
            </div>
            <div className="hidden lg:flex items-center">
              <div className="flex items-start mr-3">
                <div className="flex h-9 w-9 rounded-full overflow-hidden">
                  <img
                    src={
                      "data:image/svg+xml;charset=utf-8," +
                      courseWatched.course?.category?.icon
                    }
                    alt="Avatar"
                    className="h-full w-full object-cover"
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <span className="text-sm font-semibold mt-2 text-black dark:text-white/[0.7] flex items-center">
                {courseWatched.course?.category?.name}
                  {true && (
                    <BsFillCheckCircleFill className="text-black/[0.7] dark:text-white/[0.5] text-[12px] ml-1" />
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CourseWatchedCard;
