import {createContext, useEffect, useState} from "react";
import {MENU_ICON_SIZE, menu as defaultMenu} from "../utils/constants";
import {axiosClient} from "@/utils/HttpClient";
import {useParams} from "react-router-dom";
import {CourseLevelDTO} from "@/generated";


/**
 * will be used as a global context for things that are needed globally
 * for specific needs we will create separate contexts
 */
export const DataContext = createContext();

export const AppContext = (props) => {
    const {categoryId} = useParams()

    const [loading, setLoading] = useState("loading");
    const [recentCourses, setRecentCourses] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedCourseLevel, setSelectedCourseLevel] = useState<CourseLevelDTO>();
    const [courseLevels, setCourseLevels] = useState<CourseLevelDTO[]>();
    const [mobileMenu, setMobileMenu] = useState(false);
    const [theme, setTheme] = useState(null);
    const [coursCategories, setCoursCategories] = useState([])
    const [menu, setMenu] = useState([])


    useEffect(() => {
        //fetching cours categories to be added to the menu
        setLoading(true);
        axiosClient.get('/course-categories').then(res => {
            let cats = res.data
            setCoursCategories(cats);
            setSelectedCategory(cats?.find(cat => cat.id == categoryId)?.name)
            let newMenu = [...defaultMenu];
            // we insert them in the menu from the 2rd position, then add a divider on the last category. To create a visual separation
            newMenu.splice(1, 0, ...cats?.map((cat, index) => {
                return {
                    "name": cat.name,
                    "icon": createSVGIconFromText(cat.icon),
                    path: "/categorie/" + cat.uuid,
                    "divider": cats.length == index + 1
                }
            }))
            setMenu(newMenu);
        }).finally(() => {
            setLoading(false);
        })
    }, [])

    useEffect(() => {
        //fetching courseLevels
        setLoading(true);
        axiosClient.get('/course-levels').then(res => {
            setCourseLevels(res.data);
        }).finally(() => {
            setLoading(false);
        })
    }, [])


    const createSVGIconFromText = (svgText) => {
        return <img width={MENU_ICON_SIZE} src={"data:image/svg+xml;charset=utf-8," + svgText}/>
    }

    return (
        <DataContext.Provider
            value={{
                loading,
                setLoading,
                recentCourses,
                selectedCategory,
                setSelectedCategory,
                coursCategories,
                selectedCourseLevel,
                setSelectedCourseLevel,
                courseLevels,
                setCourseLevels,
                mobileMenu,
                setMobileMenu,
                theme,
                setTheme,
                menu
            }}
        >
            {props.children}
        </DataContext.Provider>
    );
};

export default AppContext;
