import "./style.css";
import { useEffect, useState } from "react";
import { MdOutlineSecurityUpdateGood } from "react-icons/md";
import {ReloadIcon} from "@radix-ui/react-icons";
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import {
    MembershipDTO, MembershipDTOPaymentRequestStatusEnum,
    PaymentData,
    PaymentDataPaymentMethodEnum
} from "@/generated";

export const PayWithMobileMoney = (props : any) => {

    const paymentData : PaymentData  = props.paymentData;
    const sendPaymentRequest = props.sendPaymentRequest;
    const isWaitingPaymentResponse = props.isWaitingPaymentResponse;
    const membership : MembershipDTO = props.membership;

    const [phone, setPhone] = useState<string>("")
    const [phoneValid, setPhoneValid] = useState<string>("")

    useEffect(() => {
        if(phoneNumberIsValid(phone)){
            setPhoneValid(phone);
        }else{
            setPhoneValid("")
        }
    }, [phone])


    const submitForm = ()=>{
        sendPaymentRequest({...paymentData, paymentPhoneNumber: phoneValid})
    }


const phoneNumberIsValid =  (myPhoneNumber : string)=>{
    return myPhoneNumber?.length > 7;
}
    const numberType =
        paymentData?.paymentMethod === PaymentDataPaymentMethodEnum.AirtelMoney
            ? "Airtel Money"
            : paymentData?.paymentMethod === PaymentDataPaymentMethodEnum.Mpesa
                ? "Mpesa"
                : "Orange Money";

    return (
        <section className="max-w-xl mx-auto px-4 md:px-8">
            <div className="space-y-3 text-center">

                <MdOutlineSecurityUpdateGood size={50} className="text-gray-300 mx-auto "/>

                <h3 className="text-3xl text-gray-800 font-bold">
                    Numéro de compte {numberType}
                </h3>
                <p className="text-gray-400 leading-relaxed">
                    Veuillez entrer le numéro pour effectuer le paiement
                </p>
            </div>

            <div className="mt-5 sm:mt-10">
                <ul className="mt-3 flex flex-col">
                    <li className="inline-flex items-center gap-x-2 py-3 px-4 text-md font-semibold bg-gray-50 border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200">
                        <div className="flex items-center justify-between w-full">
                            <span>Montant total à payer :</span>
                            <span>${paymentData?.membershipPlan?.price * paymentData?.monthCount}</span>
                        </div>
                    </li>
                </ul>
            </div>
            <div className={membership?.paymentRequestStatus === MembershipDTOPaymentRequestStatusEnum.Completed ? 'hidden' : 'mt-6'}>
                <form
                    onSubmit={(e) => e.preventDefault()}
                    className="items-center justify-center sm:flex">
                    <Input type="tel"
                           className="mr-4 mb-4 sm:mb-0 h-10"
                           disabled={isWaitingPaymentResponse}
                           value={phone}
                           onChange={(e) => setPhone(e.target.value)}
                           placeholder="(+243)" />

                    {!isWaitingPaymentResponse && phoneValid &&
                        <Button className="h-10 w-full" onClick={() => submitForm() }
                                variant="destructive">Demander le PIN sur mon téléphone</Button>}

                    {!isWaitingPaymentResponse && !phoneValid && <Button disabled className="h-10 w-full">
                        Demander le PIN sur mon téléphone
                    </Button>}

                    {isWaitingPaymentResponse && <Button disabled className="h-10 w-full">
                        <ReloadIcon className="mr-2 h-4 w-4 animate-spin"/>
                        En attente de confirmation du PIN
                    </Button>}

                </form>

            </div>


        </section>
    )
}
