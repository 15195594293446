import { useContext, useEffect, useRef, useState } from "react";
import { OAuthContext } from "../../context/AuthContext.tsx";
import { Link } from "react-router-dom";
import { VscAccount } from "react-icons/vsc";

export const AvatarMenue = () => {
  const [state, setState] = useState(false);
  const profileRef = useRef<any>();
  const { logout } = useContext(OAuthContext);


  const navigation = [
    { title: "Ecrire un commentaire", path: import.meta.env.VITE_FEEDBACK_LINK },
    { title: "Paramètres", path: "/parametres/abonnement" },
  ];

  useEffect(() => {
    const handleDropDown = (e: any) => {
      if (!profileRef?.current?.contains(e.target)) setState(false);
    };
    document.addEventListener("click", handleDropDown);
  }, []);

  return (
    <div className="relative border-none">
      <div className="">
        <button
          ref={profileRef}
          className=" w-10 h-10 outline-none rounded-full ring-offset-2 ring-gray-200 block"
          onClick={() => setState(!state)}
        >
          <VscAccount size={25}
            className="rounded-full"
          />
        </button>
      </div>
      <ul
        className={`bg-white top-8 right-0 absolute border rounded-md w-52 shadow-md space-y-0 mt-0 ${
          state ? "" : "hidden"
        }`}
      >
        {navigation.map((item, idx) => (
          <li key={idx}>
            <Link
              className="block text-gray-600 hover:text-gray-900 hover:bg-gray-50 p-3"
              to={item.path}
            >
              {item.title}
            </Link>
          </li>
        ))}
        <button onClick={()=>logout()} className="block w-full text-justify text-gray-600 hover:text-gray-900 border-t py-3 hover:bg-gray-50 p-3">
          Déconnexion
        </button>
      </ul>
    </div>
  );
};
