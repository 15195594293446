import React, {useContext, useEffect, useState} from "react";
import { Check, ChevronsUpDown } from "lucide-react"

import { cn } from "@/lib/utils"
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/components/ui/command"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"
import {DataContext} from "@/context/AppContext";
import {CourseLevelDTO} from "@/generated";
import {Button} from "@/components/ui/button";


export const CourseLevelFilter = (props: any) => {
    const [open, setOpen] = React.useState(false)
    const { selectedCourseLevel, setSelectedCourseLevel, courseLevels, setCourseLevels } = useContext(DataContext);
    const findCourseLevelByName = (name: string) : CourseLevelDTO => {
        return courseLevels.find((course) => course.name === name);
    }
    return (
        <>
            <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                    <Button
                        variant="outline"
                        color="primary"
                        role="combobox"
                        aria-expanded={open}
                        className="w-[170px] justify-between"
                    >
                        {selectedCourseLevel
                            ? "Niveau : "+selectedCourseLevel.name
                            : "Tous les niveaux"}
                        <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-[170px] p-0">
                    <Command>
                        <CommandInput placeholder="Rechercher..." />
                        <CommandList>
                            <CommandEmpty>Aucune donnée</CommandEmpty>
                            <CommandGroup>
                                <CommandItem key="-1" value="Tous les niveaux" onSelect={() => {
                                    setSelectedCourseLevel(null);
                                    setOpen(false)
                                }}>
                                    <Check
                                        className={cn(
                                            "mr-2 h-4 w-4",
                                            selectedCourseLevel ? "opacity-0" : "opacity-100"
                                        )}
                                    />
                                    Tous les niveaux
                                </CommandItem>
                                {courseLevels?.map((level : CourseLevelDTO) => (
                                    <CommandItem
                                        key={level.id}
                                        value={level.name}
                                        onSelect={(currentValue) => {
                                            setSelectedCourseLevel(findCourseLevelByName(currentValue))
                                            setOpen(false)
                                        }}
                                    >
                                        <Check
                                            className={cn(
                                                "mr-2 h-4 w-4",
                                                selectedCourseLevel === level.name ? "opacity-100" : "opacity-0"
                                            )}
                                        />
                                        {level.name}
                                    </CommandItem>
                                ))}
                            </CommandGroup>
                        </CommandList>
                    </Command>
                </PopoverContent>
            </Popover>

        </>
    );
};
