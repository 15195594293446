import React, { useContext, useEffect, useState } from "react";

import { DataContext } from "../context/AppContext";
import UseOnline from "../utils/UseOnline";
import OfflineComponent from "../shared/OfflineComponent";
import ShimmerVideoCard from "../shared/ShimmerVideoCard";
import { axiosClient } from "../utils/HttpClient";
import CourseCard from "../components/CourseCard";
import { CourseCategoryDTO, CourseDTO } from "../generated";
import { useParams } from "react-router-dom";
import { Pagination } from "../components/Pagination";
import {CourseLevelFilter} from "@/components/CourseLevelFilter";

const Category = () => {
  const { selectedCategory, coursCategories,setSelectedCategory, selectedCourseLevel } = useContext(DataContext);
  const isOnline = UseOnline();
  const [courses, setCourses] = useState<Array<CourseDTO>>([]);
  const [loading, setLoading] = useState(false);
  const { categoryUUID } = useParams();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = import.meta.env.VITE_ELEMENT_PER_PAGE;

  useEffect(() => {
    document.getElementById("root")?.classList.remove("custom-h");
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const uuid = categoryUUID || findCategoryByName(selectedCategory)?.uuid;
    if (uuid) {
      fetchCoursesByCategoryUUID(uuid);
    }
  }, [selectedCategory,currentPage,categoryUUID,selectedCourseLevel]);



  const fetchCoursesByCategoryUUID = (categoryUUID: String) => {
    setLoading(true);
    const uri = `/courses?categoryUUID.equals=${categoryUUID}${
        selectedCourseLevel ? "&levelId.equals=" + selectedCourseLevel.id : ""
    }&page=${currentPage}&size=${pageSize}&sort=lastModifiedDate,desc`;


    axiosClient
      .get(uri)
      .then((res) => {
        setCourses(res.data);
        setTotalPages(res.headers["x-total-pages"])
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const findCategoryByName = (name: string): CourseCategoryDTO => {
    return coursCategories.find((cat: CourseCategoryDTO) => cat.name == name);
  };

  return (
    <>
      {!isOnline && (
        <div className="grow  h-full overflow-y-auto bg-black">
          <OfflineComponent />
        </div>
      )}

      {isOnline && (
        <div className=" grow bg-white pb-12">
          <CourseLevelFilter/>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 pt-2">
            {/* When loading is true means data is not fetched yet meanwhile that time show Shimmer UI
             when data fetching is done means loading is false and that means we get recentCourses then show the data in the dom */}
            {loading
              ? Array(24)
                  .fill("")
                  .map((e, index) => {
                    return <ShimmerVideoCard key={index} />;
                  })
              : courses?.map((course: CourseDTO) => {
                  return <CourseCard key={course.id} course={course} />;
                })}
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onSelectPage={(page: number) => setCurrentPage(page)}
          />
        </div>
      )}
    </>
  );
};

export default Category;
