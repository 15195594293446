import axios from "axios";

//const BASE_URL = "https://youtube138.p.rapidapi.com";

const BASE_URL ="https://run.mocky.io/v3/24fdf901-ece8-446d-8260-e41bc246da76"
const options ={}
/*
const options = {
  params: { hl: "en", gl: "US" },
  headers: {
    "X-RapidAPI-Key": process.env.REACT_APP_YOUTUBE_API_KEY,
    "X-RapidAPI-Host": "youtube138.p.rapidapi.com",
  },
};
*/
export const fetchDataFromApi = async (url) => {
  const { data } = await axios.get(`${BASE_URL}/${url}`, options);
  return data;
};
