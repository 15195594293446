import {useState } from "react"
import logoMpesa from "../../assets/img/mpesa_w100.png"
import airtelMoney from "../../assets/img/aitelmoney_w100.png"
import orangeMoney from "../../assets/img/orangemoney_w100.png"


export const PaymentMethod = ({ step, setStep, paymentData, setPaymentData }) => {
    const[selectedCode,setSelectedCode ]=useState(paymentData.paymentMethod||"AIRTEL_MONEY")

    const selecPaymentMethod = ()=>{
        setPaymentData({ ...paymentData, paymentMethod: selectedCode })
        setStep(step + 1)
    }

    const radios = [
        {
            name: "Airtel Money",
            code:"AIRTEL_MONEY",
            description: "Payer avec un compte Airtel Money RDC",
            icon: <img width="50" src={airtelMoney} />
        },
        {
            name: "M-Pesa",
            code : "MPESA",
            description: "Payer avec un compte M-pesa vodcom RDC",
            icon: <img width="50" src={logoMpesa} />

        },
        {
            name: "Orange Money",
            code :"ORANGE_MONEY",
            description: "Payer avec un compte Orange-Money RDC",
            icon: <img width="50" src={orangeMoney} />

        }
        /*,{
            name: "Code d'activation",
            code :"VOUCHER",
            description: "Se réabonner à l'aide d'un code d'activation",
            icon: <img width="50" src={carte} />

        }*/
    ]

    return (
        <div className="max-w-md mx-auto px-4">

            <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">Méthode de paiement</h3>
            <ul className="mt-6 space-y-3">
                {
                    radios.map((item, idx) => (
                        <li key={idx} onClick={()=>setSelectedCode(item.code)}>
                            <label htmlFor={item.name} className="block relative">
                                <input id={item.name} type="radio" defaultChecked={item.code===selectedCode} name="payment" className="sr-only peer" />
                                <div className=" h-24 w-full flex gap-x-3 items-center p-4 cursor-pointer rounded-lg border bg-white shadow-sm ring-orange-white peer-checked:ring-2 duration-200">
                                    <div className="flex-none">
                                        {item.icon}
                                    </div>
                                    <div>
                                        <h3 className="leading-none text-gray-800 font-medium pr-3">
                                            {item.name}
                                        </h3>
                                        <p className="mt-1 text-sm text-gray-600">
                                            {item.description}
                                        </p>
                                    </div>
                                </div>
                                <div className="absolute top-4 right-4 flex-none flex items-center justify-center w-4 h-4 rounded-full border peer-checked:bg-orange-white text-white peer-checked:text-white duration-200">
                                    <svg className="w-2.5 h-2.5" viewBox="0 0 12 10"><polyline fill="none" strokeWidth="2px" stroke="currentColor" strokeDasharray="16px" points="1.5 6 4.5 9 10.5 1"></polyline></svg>
                                </div>
                            </label>
                        </li>
                    ))
                }
            </ul>
            <a onClick={()=> selecPaymentMethod()} href="#" className="block mt-8 py-2 px-4 text-center text-white font-medium bg-orange-white duration-150 hover:bg-orange active:bg-orange rounded-lg shadow-lg hover:shadow-none">
                                Suivant
                            </a>
        </div>
    )
}
