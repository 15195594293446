import { Link } from "react-router-dom";
import VideoLength from "../shared/VideoLength";
import { CourseDTO } from "../generated";
import React from "react";
import { Badge } from "./ui/badge";
import dayjs from "dayjs";

const BASE_URL = import.meta.env.VITE_BACKEND_SERVER;

const CourseCard = (props: any) => {
  const course: CourseDTO = props.course;
  return (
    <Link to={`/cours/${course.uuid}`}>
      <article className="relative mx-auto my-4 shadow-lg border rounded-md duration-300 hover:shadow-sm">
       <div className="relative">

          <img
          src={`${BASE_URL}/content/${course.thumbnailFileName}`}
          loading="lazy"
          alt={course.name as string}
          className="w-full h-30 rounded-t-md object-cover "
        />
                  <VideoLength time={course?.durationSec} />

       </div>


        <div className="flex items-center mt-2 pt-3 ml-4 mr-2 pb-3">
          <div className="flex-none w-10 h-8 rounded-full pb-">
            <img
                src={"data:image/svg+xml;charset=utf-8," + course?.category?.icon}
                className="w-8 h-8 rounded-full"
              alt="categorie"
            />
          </div>
          <div className="ml-3">
            <div className="block text-md text-gray-900">{course?.category?.name} / {course.name}
                <Badge variant="destructive" className="ml-2">{course.level.name}</Badge>
            </div>
            <span className="block text-gray-400 text-sm hidden">{dayjs(course.createdDate).format('DD/MM/YYYY')}</span>
          </div>
        </div>
      </article>

    </Link>
  );
};

export default CourseCard;
