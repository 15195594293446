import React from "react";
import { IoFlagOutline, IoHeartOutline, IoHelpCircleOutline, IoHomeOutline, IoSettingsOutline, IoTimeOutline } from "react-icons/io5";
export const menu = [
  { name: "Accueil", path: "/cours", icon: <IoHomeOutline />, divider: false },
  /* { name: "Favoris",path:"/favoris" , icon: <IoHeartOutline />, divider : false },
   { name: "Historique", path:"/historique", icon: <IoTimeOutline />, divider : true  },*/
   { name: "Paramètres",path:"/parametres", icon: <IoSettingsOutline /> ,divider : false}
  /*{ name: "Aide", icon: <IoHelpCircleOutline />,divider : false }*/
];

export const MENU_ICON_SIZE = 20;