import {useState } from "react"


export const MembershipValidity = ({ step, setStep, paymentData, setPaymentData }) => {
    const[selectedMonthCount,setSelectedMonthCount ]=useState(paymentData.monthCount|1)

    const applyMonthCount = ()=>{
        setPaymentData({ ...paymentData, monthCount: selectedMonthCount })
        setStep(step + 1)
    }

    return (
        <div className="max-w-md mx-auto px-4">

            <div className='relative max-w-2xl mx-auto sm:text-center'>
                <h3 className='text-gray-800 text-3xl font-semibold sm:text-4xl'>
                    Veuillez choisir la durée
                </h3>
                <div className='mt-3 max-w-xl'>
                    <p>
                        Nombre de mois pour votre abonnement
                    </p>
                </div>
            </div>

            <div className="mt-5 sm:mt-10">
                <ul className="mt-3 flex flex-col">
                    <li className="inline-flex items-center gap-x-2 py-3 px-4 text-md border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-neutral-700 dark:text-neutral-200">
                        <div className="flex items-center justify-between w-full">
                            <span>Durée</span>
                            <select value={selectedMonthCount} onChange={(e) => setSelectedMonthCount(e.target.value)}>
                                {
                                    Array(12).fill(0).map((item, index) => (
                                        <option key={index} value={index + 1}>{index + 1} mois</option>))
                                }
                            </select>
                        </div>
                    </li>
                    <li className="inline-flex items-center gap-x-2 py-3 px-4 text-md border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-neutral-700 dark:text-neutral-200">
                        <div className="flex items-center justify-between w-full">
                            <span>Montant mensuel</span>
                            <span>${paymentData.membershipPlan.price}</span>
                        </div>
                    </li>
                    <li className="inline-flex items-center gap-x-2 py-3 px-4 text-md font-semibold bg-gray-50 border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200">
                        <div className="flex items-center justify-between w-full">
                            <span>Total à payer</span>
                            <span>${paymentData.membershipPlan.price * selectedMonthCount}</span>
                        </div>
                    </li>
                </ul>
            </div>
            <a onClick={() => applyMonthCount()} href="#"
               className="block mt-8 py-2 px-4 text-center text-white font-medium bg-orange-white duration-150 hover:bg-orange active:bg-orange rounded-lg shadow-lg hover:shadow-none">
                Suivant
            </a>
        </div>
    )
}
