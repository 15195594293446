import { LessonDTO } from "../generated";
import VideoLength from "../shared/VideoLength";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { abbreviateNumber } from "js-abbreviation-number";
const BASE_URL = import.meta.env.VITE_BACKEND_SERVER;

const LessonCard = (props: any) => {
  const lesson: LessonDTO = props.lesson;
  return (
    <div className="flex mb-4">
      <div className="relative h-24 rounded-md lg:h-20 xl:h-24 w-40 min-w-[168px] lg:w-32 lg:min-w-[128px] xl:w-40 xl:min-w-[168px] bg-slate-800 overflow-hidden">
        <img
          src={`${BASE_URL}/content/${lesson.thumbnailFileName}`}
          alt="thumbnails"
          className="h-full w-full object-cover "
        />
        {lesson?.viewCount && <VideoLength time={lesson?.viewCount} />}
      </div>

      <div className="flex text-black dark:text-white">
        {/* <div className="flex items-start">
          <div className="flex h-9 w-9 rounded-full overflow-hidden">
            <img
              src={video?.author?.avatar[0]?.url}
              alt="Avatar"
              className="h-full w-full object-cover"
            />
          </div>
        </div> */}

        <div className="flex flex-col ml-3 overflow-hidden">
          <span className="text-sm font-bold line-clamp-2 lg:text-sm xl:text-sm ">
            {lesson?.name}
          </span>

        {/*  <span className="text-[12px] font-bold dark:font-semibold mt-2 text-black/[0.8] dark:text-white/[0.7] flex items-center lg:text-[12px] xl:text-[12px]">
            {lesson?.name}

              <BsFillCheckCircleFill className="text-black/[0.7] dark:text-white/[0.5] text-[12px] ml-1 lg:text-[12px] xl:text-[12px]" />

          </span>

          <div className="flex text-[12px] font-semibold text-black/[0.8] dark:text-white/[0.7] truncate overflow-hidden lg:text-[12px] xl:text-[12px]">
            <span>{`${abbreviateNumber(lesson?.viewCount, 2)} views`}</span>
            <span className="flex text-[24px] text-black/[0.8] dark:text-white/[0.7] font-bold leading-none relative top-[-10px] mx-1">
              .
            </span>
            <span className="truncate">{lesson?.name}</span>
          </div>*/}
        </div>
      </div>
    </div>
  );
};

export default LessonCard;
