import { Link } from "react-router-dom";
import airtelMoney from "../../assets/img/aitelmoney_w100.png";
import mpesa from "../../assets/img/mpesa_w100.png";
import orangeMoney from "../../assets/img/orangemoney_w100.png";
import voucher from "../../assets/img/carte.png";

import { MembershipDTO } from "@/generated";
import { useEffect, useState } from "react";
import { axiosClient } from "@/utils/HttpClient";
import Alert from "../../shared/Alert";
import dayjs from "dayjs";

export const Membership = () => {
  const [activeMembership, setActiveMembership] = useState<MembershipDTO>();
  const [memberships, setMemberships] = useState<Array<MembershipDTO>>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axiosClient
      .get(
        "/memberships?paymentRequestStatus.equals=COMPLETED&membershipEndAt.greaterThan=" +
          new Date().toISOString() +
          "&page=0&size=1&sort=lastModifiedDate,asc"
      )
      .then((res) => setActiveMembership(res.data[0]))
      .finally(() => setLoading(false));
  }, []);
  useEffect(() => {
    axiosClient
      .get("/memberships?page=0&size=10&sort=membershipEndAt,desc")
      .then((res) => setMemberships(res.data))
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      {activeMembership && !loading && (
        <div className="w-full mb-10 max-w-2xl p-4 lg:max-w-3xl bg-white border border-gray-200 rounded-lg shadow sm:p-6">
          <h5 className="mb-3 text-lg font-semibold text-gray-900 md:text-xl">
            Abonnement Actif : {activeMembership.plan?.name}
          </h5>
          <p className="text-md font-normal text-gray-500">
            Valide jusqu'au {dayjs(activeMembership.membershipEndAt).format('DD/MM/YYYY')}
          </p>
          <div className="flex items-center py-6 text-base font-bold text-gray-900 group">
            {activeMembership.paymentMethod == "ORANGE_MONEY" && (
              <img width={40} src={orangeMoney} />
            )}
            {activeMembership.paymentMethod == "AIRTEL_MONEY" && (
              <img width={40} src={airtelMoney} />
            )}
            {activeMembership.paymentMethod == "MPESA" && (
              <img width={40} src={mpesa} />
            )}
            {activeMembership.paymentMethod == "VOUCHER" && (
              <img width={40} src={voucher} />
            )}
            <span className="flex-1 ms-3 whitespace-nowrap">
              {activeMembership.paymentMethod == "ORANGE_MONEY" && (
                <span>Par Orange Money</span>
              )}
              {activeMembership.paymentMethod == "AIRTEL_MONEY" && (
                <span>Par Airtel Money</span>
              )}
              {activeMembership.paymentMethod == "MPESA" && (
                <span>Par MPESA</span>
              )}
              {activeMembership.paymentMethod == "VOUCHER" && (
                <span> Par Code d'activation</span>
              )}
            </span>
            <span className="inline-flex items-center justify-center px-2 py-0.5 ms-3 text-xs  text-gray-500 bg-gray-200 rounded">
              {activeMembership.paymentMobileNumber}
            </span>
          </div>
        </div>
      )}

        <div className="w-full mb-10 max-w-2xl lg:max-w-3xl p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6">
          {!activeMembership && !loading && ( <Alert
            type="warning"
            title="Abonnement inactif"
            content="Vous n'avez pas d'abonnement actif pour le moment. Veuillez activer un abonnement"
          />)}
          <Link
            to="/paiement"
            className="py-2 px-3 ml-5 mt-5 lg:ml-16 my-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-orange-white text-white hover:bg-orange disabled:opacity-50 disabled:pointer-events-none"
          >
            Ajouter un abonnement
          </Link>
        </div>


      {memberships && !loading && (
        <div className="w-full max-w-2xl lg:max-w-3xl ">
          <div className="flex flex-col">
            <div className="-m-1.5 overflow-x-auto">
              <div className="p-1.5 min-w-full inline-block align-middle">
                <div className="bg-white border border-gray-200 rounded-xl pb-6 shadow-lg overflow-hidden">
                  <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200">
                    <div>
                      <h2 className="text-xl font-semibold text-gray-800">
                        Historique des paiements
                      </h2>
                      <p className="text-sm text-gray-600">
                        Nous n'affichons que les 10 dernières transactions
                      </p>
                    </div>

                    <div>
                      <div className="inline-flex gap-x-2"></div>
                    </div>
                  </div>

                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">
                              Montant
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">
                              status
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">
                              Validité
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">
                              COMPTE
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-end"></th>
                      </tr>
                    </thead>

                    <tbody className="divide-y divide-gray-200 ">
                      {memberships.map((membership, index) => (
                        <tr key={index} className="bg-white hover:bg-gray-50">
                          <td className="size-px whitespace-nowrap">
                            <button
                              type="button"
                              className="block"
                              data-hs-overlay="#hs-ai-invoice-modal"
                            >
                              <span className="block px-6 py-2">
                                <span className="text-sm text-gray-600">
                                  {membership.currency}{" "}
                                  {membership.totalPrice}
                                </span>
                              </span>
                            </button>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <button
                              type="button"
                              className="block"
                              data-hs-overlay="#hs-ai-invoice-modal"
                            >
                              <span className="block px-6 py-2">
                                {membership.paymentRequestStatus ==
                                  "COMPLETED" && (
                                  <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full">
                                    <svg
                                      className="size-2.5"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                    </svg>
                                    reçu
                                  </span>
                                )}

                                {membership.paymentRequestStatus ==
                                  "CANCELLED" && (
                                  <span className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                    <svg
                                      className="size-2.5"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                                    </svg>
                                    Non reçu
                                  </span>
                                )}

                                {membership.paymentRequestStatus ==
                                  "SUBMITTED" && (
                                  <span className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-yellow-300 text-yellow-600">
                                    <svg
                                      className="size-2.5"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                                    </svg>
                                    En attente
                                  </span>
                                )}
                              </span>
                            </button>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <button
                              type="button"
                              className="block"
                              data-hs-overlay="#hs-ai-invoice-modal"
                            >
                              <span className="block px-4 py-2">
                                <span className="text-sm text-gray-600">
                                  {dayjs(membership.membershipStartAt).format('DD/MM/YYYY')} -{" "}
                                  {dayjs(membership.membershipEndAt).format('DD/MM/YYYY')}
                                </span>
                              </span>
                            </button>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <span className="px-6 py-1.5">
                              <span className="py-1 px-2 inline-flex justify-center items-center font-medium bg-white text-gray-700 shadow-sm align-middle transition-all text-sm">
                                {membership.paymentMethod == "ORANGE_MONEY" && (
                                  <img width={15} src={orangeMoney} />
                                )}
                                {membership.paymentMethod == "AIRTEL_MONEY" && (
                                  <img width={15} src={airtelMoney} />
                                )}
                                {membership.paymentMethod == "MPESA" && (
                                  <img width={15} src={mpesa} />
                                )}
                                {membership.paymentMethod == "VOUCHER" && (
                                  <img width={15} src={voucher} />
                                )}
                              <span className="ml-3">{membership.paymentMobileNumber || "xxxxxxx"}</span>
                              </span>
                            </span>
                          </td>
                        </tr>
                      ))}

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
